import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import ProductDetails from './view/ProductDetails'
import { HelmetProvider } from 'react-helmet-async'

import './App.scss'

const App = () => (
	<HelmetProvider>
		<Router>
			<Routes>
				<Route path={`/`} element={<ProductDetails />} />
			</Routes>
		</Router>
	</HelmetProvider>
)

export default App

/* eslint-disable array-callback-return */
import React, { Fragment, useEffect, useState } from 'react'
import Header from '../components/Header'
import { Container, Col, Row, FormGroup, Input, Label } from 'reactstrap'
import { FontAwesome } from 'react-web-vector-icons'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const products = {
	p1: {
		name: "The Column Women's Jeans",
		merchantBadge: "Demo's Made & Classic",
		description:
			"<p>Equal parts progressive and retro, we like to say that the Column Pants are inspired by your dad. In actuality, these jeans marry a straight fit with a high rise — a construction that's a little wrong and also completely right. This is the perfect fit for playing with proportion, whether it's an oversized Trucker Jacket or a cropped sweater. We made this pair from dark wash denim for that timeless blue jeans look.</p><UL> <LI> A fit inspired by your Dad, upgraded with a flattering high rise </LI><LI> Carefully crafted with organic cotton </LI><LI> Features triple needle stitching </LI><LI> The perfect fit for having fun with proportions </LI> </UL>",
		price: '$188.00',
		galleryImage: [
			{
				altText: "The Column Women's Jeans",
				format: 'Quickview_Desktop',
				galleryIndex: 0,
				imageType: 'GALLERY',
				url: require('../assets/images/img-1.avif'),
			},
			{
				altText: "The Column Women's Jeans",
				format: 'Quickview_Desktop',
				galleryIndex: 1,
				imageType: 'GALLERY',
				url: require('../assets/images/img-2.avif'),
			},
			{
				altText: "The Column Women's Jeans",
				format: 'Quickview_Desktop',
				galleryIndex: 2,
				imageType: 'GALLERY',
				url: require('../assets/images/img-3.avif'),
			},
			{
				altText: "The Column Women's Jeans",
				format: 'Quickview_Desktop',
				galleryIndex: 3,
				imageType: 'GALLERY',
				url: require('../assets/images/img-4.avif'),
			},
		],
	},
}

const swatches = [
	{
		colorName: 'Black Star - Black',
		imageUrl: require('../assets/images/swatch-black.jpeg'),
	},
	{
		colorName: 'Coastal Blue - Dark Wash',
		imageUrl: require('../assets/images/swatch-blue.jpeg'),
	},
	{
		colorName: 'Optic Indigo - Medium Wash',
		imageUrl: require('../assets/images/swatch-gray.jpeg'),
	},
]

const waist = [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34]
const len = [27, 30]
const afterPayLogo = require('../assets/images/afterpay-logo.png')

const ProductDetails = () => {
	const params = useParams()
	const [selectedImg, setSelectedImg] = useState(1)
	const productId = params.id || 'p1'
	const product = products[productId]
	const getThumnailList = () => {
		return product.galleryImage.map((item, index) => (
			<div key={index} className='thumbnail' onClick={() => setSelectedImg(index)}>
				<img alt='' src={item.url} />
			</div>
		))
	}

	const [btnText, setbtnText] = useState('')

	function isEmptyObj(obj) {
		if (obj === undefined || obj === null || obj === '') return true
		return Object.entries(obj).length === 0 && obj.constructor === Object
	}

	useEffect(() => {
		setInterval(() => {
			Object.entries(localStorage).map(([key, valueJSON]) => {
				if (key === 'aistetic_size_recommendation') {
					const value = JSON.parse(valueJSON)
					if (isEmptyObj(value)) {
						setbtnText('')
						return
					}

					Object.entries(value).map(([k, cloth]) => {
						const clothings = Object.values(cloth)
						if (clothings.length > 0) {
							setbtnText(clothings[0]['size'])
							return
						}
					})
				}
			})
		}, 200)
	}, [])

	const getSwatches = () => {
		return swatches.map((item, i) => (
			<span key={i} className='swatch'>
				<img alt='' src={item.imageUrl} />
			</span>
		))
	}

	return (
		<Container fluid>
			<Header />
			<Container fluid className='pd-section'>
				<Row>
					<Col className='gallery-section' sm={12} md={6}>
						<div className='thumbnail-list'>{getThumnailList()}</div>
						<div className='hero-image'>
							<div className='floating-child' data-target='aistetic'>
								<div className='floating-child-new'>NEW</div>
								<div className='floating-child-room'>VIRTUAL FITTING ROOM</div>
							</div>
							<img alt='' src={product.galleryImage[selectedImg].url} />
						</div>
					</Col>
					<Col className='summary-section' sm={12} md={6}>
						<div className='page-title-container'>
							<div className='page-title'>
								<div className='merchant-badge'>{product.merchantBadge}</div>
								<h1 className='product-title'>{product.name}</h1>
							</div>
							<div className='rating-section'>
								<FontAwesome name='star' color='#000000' size={20} />
								<FontAwesome name='star' color='#000000' size={20} />
								<FontAwesome name='star' color='#000000' size={20} />
								<FontAwesome name='star' color='#000000' size={20} />
								<FontAwesome name='star-o' color='#000000' size={20} />
							</div>
						</div>

						<div className='swatch-section'>
							<div className='swatch-name'>{swatches[0].colorName}</div>
							{getSwatches()}
						</div>
						<div className='box-separator no-show-mobile'></div>
						<div
							dangerouslySetInnerHTML={{
								__html: `	<style>
								@keyframes lightning{0%{opacity:0;transform:translateX(-100%);width:0%}50%{opacity:1;transform:translateX(0);width:50%}100%{opacity:0;transform:translateX(100%);width:60%}}.lightning-button{display:inline-block;padding:10px 20px;background-color:#2196f3;color:#fff;font-size:16px;border:none;outline:0;cursor:pointer;overflow:hidden}.lightning-button::after{content:"";position:absolute;top:0;left:40px;width:5%;height:100%;background-color:rgba(255,255,255,.5);opacity:0;pointer-events:none;transition:opacity .3s ease-in-out}.lightning-button:hover::after{opacity:1;animation:lightning 1s linear infinite}.floating-child{position:absolute;top:-14px;left:-15px;float:left;}
							  </style>`,
							}}></div>

						<div
							className='widget-trigger'
							data-target='aistetic'
							style={{
								marginTop: '20px',
								marginBottom: '30px',
								cursor: 'pointer',
								position: 'relative',
							}}>
							<div className='floating-child'>
								<img
									loading='lazy'
									height='34'
									src='https://aistetic-assets-tm6a4f1x7d.s3.eu-west-2.amazonaws.com/widget-button-text-new.gif'
									alt='NEW'
									// style='width: 60px;'
								/>
							</div>
							<div
								className='lightning-button'
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									padding: '14px 15px 14px 10px',
									gap: '15px',
									background: '#000',
									borderRadius: '6px',
								}}>
								<img
									loading='lazy'
									width='40'
									height='40'
									src='https://aistetic-assets-tm6a4f1x7d.s3.eu-west-2.amazonaws.com/t-shirt-black-yellow-bg.gif'
									style={{ cursor: 'pointer', width: '40px', height: '40px' }}
									alt='VFR'
								/>
								<div>
									{btnText === '' ? (
										<h3
											style={{
												margin: 0,
												cursor: 'pointer',
												fontSize: '16px',
												fontWeight: 800,
												color: '#fff',
											}}>
											VIRTUAL FITTING ROOM{' '}
										</h3>
									) : (
										<h3
											style={{
												margin: 0,
												cursor: 'pointer',
												fontSize: '16px',
												fontWeight: 800,
												color: '#fff',
											}}>
											Your recommended size is: {btnText}
										</h3>
									)}
									<p
										style={{
											margin: 0,
											marginTop: '2px',
											cursor: 'pointer',
											fontSize: '11px',
											color: '#fff',
											lineHeight: '15px',
										}}>
										{btnText === '' ? (
											<Fragment>
												<span
													style={{
														textDecoration: 'underline',
														marginTop: '2px',
														cursor: 'pointer',
														fontSize: '11px',
														fontWeight: '500',
														color: '#fff',
														lineHeight: '15px',
													}}>
													Enter your details
												</span>{' '}
												to find your perfect size!
											</Fragment>
										) : (
											<Fragment>
												<span
													style={{
														textDecoration: 'underline',
														marginTop: '2px',
														cursor: 'pointer',
														fontSize: '11px',
														fontWeight: '500',
														color: '#fff',
														lineHeight: '15px',
													}}>
													View
												</span>{' '}
												other size recommendations
											</Fragment>
										)}
									</p>
								</div>
							</div>
						</div>

						<div className='size-section'>
							<div className='size-title-header'>
								<div className='size-header'>Select Size</div>
							</div>
							<div className='variant-section'>
								<div className='waist-section'>
									<div className='variant-title'>Waist</div>
									<ul className='size-list'>
										{waist.map((item, index) => (
											<li key={index} className='size-item'>
												<button>
													<span>{item}</span>
												</button>
											</li>
										))}
									</ul>
								</div>
								<div className='length-section'>
									<div className='variant-title'>Length</div>
									<ul className='size-list'>
										{len.map((item, index) => (
											<li key={index} className='size-item'>
												<button>
													<span>{item}</span>
												</button>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
						<div className='box-separator no-show-mobile'></div>
						<div className='price-section'>
							<div className='price'>{product.price} </div>
							<div className='after-pay'>
								<span> Or 4 installments of $47.00 by </span>
								<img alt='' src={afterPayLogo} />
							</div>
						</div>
						<div className='qty-section'>
							<div className='qty-title'>Select Quantity</div>
							<div className='qty-counter'>
								<button className='qty-button'>-</button>
								<span className='selected-qty'>1</span>
								<button className='qty-button'>+</button>
							</div>
						</div>
						<div className='ship-options-section'>
							<FormGroup>
								<FormGroup check>
									<Input name='radio1' type='radio' />{' '}
									<Label check>
										<div className='ship-option-text'>
											<div className='ship-title'>Ship</div>
											<div className='ship-sub-title'>Select size to see if item is in stock</div>
										</div>
									</Label>
								</FormGroup>
								<FormGroup check>
									<Input name='radio1' type='radio' />{' '}
									<Label check>
										<div className='ship-option-text'>
											<div className='ship-title'>Pickup In-Store</div>
											<div className='ship-sub-title'>Select size to see if item is in stock</div>
											<div className='select-store'>Select Your Store</div>
										</div>
									</Label>
								</FormGroup>
							</FormGroup>
						</div>
						<div className='cta-section d-none d-md-block'>
							<div className='add-to-cart full-btn'>
								<button>Add to Bag</button>
							</div>
							<div className='add-to-fav full-btn'>
								<button className='ghost-btn'>Favorite</button>
							</div>
						</div>
					</Col>
				</Row>
				<Row className='pdp-specification-section'>
					<Col sm={12} md={6}>
						<div>
							<div
								dangerouslySetInnerHTML={{
									__html: product.description,
								}}></div>
						</div>
					</Col>
					<Col sm={12} md={6}>
						<div className='product-spec-overview'>
							<div className='pdp-spec-feature'>
								<div className='pdp-tabs-header'>
									<span className='pdp-tabs-title'>How it Fits</span>
								</div>
								<div className='contentContainer'>
									<ul>
										<li>Straight fit</li>
										<li>High rise</li>
										<li>Measurements based on a size 27 waist</li>
									</ul>
								</div>
							</div>
							<div className='pdp-spec-feature'>
								<div className='pdp-tabs-header'>
									<span className='pdp-tabs-title'>Composition &amp; Care</span>
								</div>
								<div className='contentContainer'>
									<ul>
										<li>100% organically grown cotton</li>
										<li>Non-stretch denim</li>
										<li>Zip fly</li>
										<li>5-pocket styling</li>
									</ul>
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<div className='stick-footer d-block d-md-none'>
					<div className='footer-btn-container'>
						<div className='footer-btn fav-btn'>
							<button>
								<FontAwesome name='heart-o' color='#b2b2b2' size={20} />
							</button>
						</div>
						<div className='footer-btn bag-btn'>
							<button>Add to Bag</button>
						</div>
					</div>
				</div>
			</Container>
			<Helmet>
				<script
					type='text/javascript'
					src={process.env.REACT_APP_AISTETIC_SCRIPT_URL}
					apiKey={process.env.REACT_APP_AISTETIC_API_KEY}
					domain={process.env.REACT_APP_AISTETIC_DOMAIN}></script>
			</Helmet>
		</Container>
	)
}

export default ProductDetails

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react'

import { Container, Col, Row, Input, InputGroup, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Feather } from 'react-web-vector-icons'

const Header = () => {
	const [modal, setModal] = useState(false)

	const toggle = () => setModal(!modal)

	const rightTopNavs = [
		{
			text: 'Log In',
			url: '',
		},
		{
			text: 'Sign Up',
			url: '',
		},
		{
			text: 'Find Stores',
			url: '',
		},
		{
			text: 'United Kingdom',
			url: '',
		},
	]
	const mainNavs = [
		{
			text: 'SHOP',
			url: '',
		},
		{
			text: 'DISCOVER',
			url: '',
		},
		{
			text: 'SECONDHAND',
			url: '',
		},
	]

	const sideNavs = [
		{
			text: 'Women',
			url: '',
		},
		{
			text: 'Men',
			url: '',
		},
		{
			text: 'Kids',
			url: '',
		},
		{
			text: 'Shoes & Accessories',
			url: '',
		},
		{
			text: 'Collections',
			url: '',
		},
	]
	const getRightNavs = () => (
		<ul>
			{rightTopNavs.map((item, index) => (
				<li key={`key-${index}`}>
					<a>{item.text}</a>
				</li>
			))}
		</ul>
	)
	const getMainNavs = () => (
		<ul>
			{mainNavs.map((item, index) => (
				<li key={`key-${index}`}>
					<a>{item.text}</a>
				</li>
			))}
		</ul>
	)

	const getSideNavs = () => (
		<ul>
			{sideNavs.map((item, index) => (
				<li key={`key-${index}`}>
					<a href='/'>{item.text}</a>
				</li>
			))}
		</ul>
	)

	const getOfferCarousel = () => (
		<>
			<Feather
				name='chevron-left'
				color='#000000'
				size={20}
				// style={{}}
			/>

			<span className='offer-name'>Blue Light Card Holders : Save 30% Details</span>

			<Feather
				name='chevron-right'
				color='#000000'
				size={20}
				// style={{}}
			/>
		</>
	)

	return (
		<Container fluid className='header-container'>
			<Row className='nav-row d-none d-md-flex'>
				<Col className=''></Col>
				<Col className='offer-carousel'>{getOfferCarousel()}</Col>
				<Col className='nav-bar'>{getRightNavs()}</Col>
			</Row>
			<Row className='search-nav-row'>
				<Col className='nav-bar d-none d-md-block'>{getMainNavs()}</Col>
				<Col className='nav-bar d-block d-md-none'>
					<div onClick={toggle}>
						<Feather
							name='menu'
							color='#000000'
							size={20}
							// style={{}}
						/>
					</div>
				</Col>
				<Col className='aistetic-logo'>
					<h4>Aistetic Demo</h4>
				</Col>
				<Col className='search-section'>
					<Feather
						name='shopping-bag'
						color='#000000'
						size={20}
						// style={{}}
					/>
					<div className='d-none d-md-block'>
						<Feather
							name='heart'
							color='#000000'
							size={20}
							// style={{}}
						/>
					</div>

					<div className='d-block d-md-none'>
						<Feather
							name='search'
							color='#000000'
							size={20}
							// style={{}}
						/>
					</div>
					<div className='d-none d-md-block'>
						<InputGroup className='search-input-group'>
							<Feather
								name='search'
								color='#000000'
								size={20}
								// style={{}}
							/>
							<Input id='exampleEmail' name='email' placeholder='What are you looking for?' type='email' />
						</InputGroup>
					</div>
				</Col>
			</Row>
			<Row className='offer-carousel-section d-block d-md-none'>
				<Col className='offer-carousel'>{getOfferCarousel()}</Col>
			</Row>
			<Modal isOpen={modal} toggle={toggle} className={`mobile-menu`} fade={false}>
				<ModalHeader toggle={toggle}>
					<h4>Aistetic Demo</h4>
				</ModalHeader>
				<ModalBody>{getSideNavs()}</ModalBody>
			</Modal>
		</Container>
	)
}

export default Header
